.bottom {
  @include row-right-center;

  height: 95px;
  position: absolute;
  right: 20px;
  bottom: 0;
  z-index: 20;
}

.left-area {
  @include wrapper-xy($width-left, 0);
  @include column-top-stretch;
  margin-right: 20px;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;

  &.collapsed {
    width: 73px;
    overflow-x: hidden;
  }

  transition: width 0.3s ease;

  .menu-button {
    border: 0;
  }
}

.right-area {
  @include wrapper-xy($width-right, 0);
  @include column-top-stretch;
  margin-bottom: 20px;
  margin-left: 15px;

  @include media($width-sm) {
    width: 100%;
    margin: 0 0 20px 0;
    max-height: 300px;
  }
}
