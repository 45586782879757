.dropdown-wrapper {
  margin-bottom: 26px;
}

.dropdown-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 100px;
  cursor: pointer;
  padding-right: 8px;

  img {
    transform: rotate(180deg);
    transition: 0.2s ease-in-out;
  }

  p {
    margin: 0;
    color: #000;

    font-size: $font-size-12;
    font-weight: $font-weight-700;
  }
}

.dropdown-content {
  overflow: hidden;
  transition: 0.2s ease-in-out;
}

.expanded {
  .dropdown-content {
    padding-top: 24px;
    transition: 0.2s ease-in-out;
  }

  .dropdown-summary {
    img {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
}
