@import './selfSufficienceSection';

.photovoltaik-title-row {
  @include row-left-center;
  gap: 15px;
  margin-bottom: 35px;

  .title-image {
    width: 150px;
  }

  .photovoltaik-title {
    @include font-size-20;
    font-weight: 700;
  }
}

.storages-selection-wrapper {
  margin-bottom: 10px;
}

.photovoltaik-subtitle {
  @include font-size-14;
  font-weight: 700;
  color: $color-h2;
}

.modulart-buttons-row {
  @include row-spacebetween-center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .module-button {
    width: 47%;
    flex: initial;
  }
}

.storage-checkboxes {
  margin-bottom: 20px;
}

.storage-select {
  margin-bottom: 50px;
}

.storage-inputs-row {
  @include row-spacebetween-center;

  .storage-input {
    width: 22%;
  }
}

.surface-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px dashed $color-input-border;
  padding: 20px;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;

  .cross-icon {
    width: 13px;
    height: 13px;
    top: 10px;
    right: 10px;
    position: absolute;
  }

  .surface-type-wrapper {
    width: 96.5%;
    @include row-left-center;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .surface-type {
    min-width: 30%;

    @include media($width-md) {
    }
  }

  .surface-input-row {
    @include row;
    width: 100%;
    gap: 30px;

    .surface-input {
      width: 30%;
      min-width: initial;
    }

    @include media($width-md) {
      gap: 15px;
    }

    .tooltip-input {
      width: 100%;
    }
  }
}

.extra-options-row {
  @include row-left-top;
  flex-wrap: wrap;

  &:last-child {
    margin-bottom: 0;
  }

  .extra-select {
    width: 60%;
    margin-top: 10px;
  }
}

.extra-option-inputs {
  @include row-left-center;
  gap: 20px;

  .extra-input {
    width: 40%;
  }
}

.chart {
  width: 300px;
  height: 300px;
}

.image-upload {
  margin-top: 20px;
}
