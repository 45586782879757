.button {
  @include column-center-center;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  background: $color-button;
  border: 1px solid $color-button-border;
  border-radius: 8px;
  width: 115px;

  &:hover,
  &:focus {
    background: $color-button-hover;
  }

  &:hover {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .inner {
    height: 42px;
    @include row-center-center;
    position: relative;

    .text {
      @include font-size-12;
      text-align: center;
      font-weight: 700;
      color: $color-button-text;
      text-transform: uppercase;

      &.collapsed {
        display: none;
      }
    }

    .icon {
      position: absolute;
      left: 16px;
      display: none;

      &.show-icon {
        display: block;
      }

      &.collapsed {
        position: initial;
        left: initial;
        display: block;
      }
    }
  }

  &.green-btn {
    background-color: $color-button-green;
    border: 0;

    .inner {
      .text {
        color: $color-button-text-green;
      }
    }

    &:hover {
      background-color: $color-button-hover-green;
    }
  }

  &.transparent-btn {
    background-color: transparent;
    border: 1px solid $color-button-border-transparent;

    .inner {
      .text {
        color: $color-button-text-transparent;
      }
    }

    &:hover {
      background-color: $color-button-hover-transparent;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.left-margin-20 {
    margin-left: 20px;
    z-index: 30;
  }

  &.disabled {
    background-color: $color-button-disabled;
    color: $color-input-border;
    border: 1px solid $color-input-border;

    &:hover {
      background-color: $color-button-disabled;
      cursor: not-allowed;
    }
  }
}
