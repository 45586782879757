.wallbox-title-row {
  @include row-left-center;
  gap: 30px;
  margin-bottom: 35px;

  .title-image {
    width: 90px;
  }

  .wallbox-title {
    @include font-size-20;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.wallbox-row {
  display: flex;
  grid-gap: 40px;
  margin-bottom: 32px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .select {
    flex: 1;
  }

  .counter {
    width: 160px;
  }

  .checkbox-container {
    display: flex;
    flex: 1;
    margin-bottom: 0;
  }
}
