.calculator-widget-wrapper {
  background: $white-bg;
  border-radius: 8px;

  color: $black;
  font-size: $font-size-12;
  font-weight: $font-weight-400;
  max-height: 92%;

  @include media($width-sm) {
    max-height: 100%;
  }

  .calculator-content {
    padding: 0 25px;
    overflow: auto;
    height: calc(100% - 44px);
  }

  .calculator-title {
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    padding: 15px 25px;
    line-height: 14px;
    background: $color-blue-500;
    color: $white;
    font-size: $font-size-12;
    font-weight: $font-weight-700;
  }

  .calculator-content-placeholder {
    -moz-border-radius-bottomleft: 8px;
    -moz-border-radius-bottomright: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    height: 44px;
    background: $white;
  }

  .calculator-divider {
    width: 100%;
    height: 1px;
    background: #979797;
    margin: 40px 0;
  }

  .calculator-accordeon-column {
    display: flex;
    flex-direction: column;
  }

  .calculator-accordeon-row {
    display: flex;
    justify-content: space-between;
  }
}

.calculator-content-section {
  margin-top: 24px; //INFO: better to use margins heree instead of paddings for "margin-collapsing" with divider and other sections.
  margin-bottom: 30px;

  h3 {
    color: $black;
    font-size: $font-size-12;
    font-weight: $font-weight-700;
    margin-bottom: 26px;
    line-height: 14px;
  }

  .description {
    font-size: $font-size-12;
    font-weight: $font-weight-400;
    margin-bottom: 26px;
    line-height: 16px;
    white-space: pre-line;
  }

  .calculator-widget-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    p {
      margin-bottom: 0;
      max-width: 75%;
      font-size: 12px;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    &-price {
      white-space: nowrap;
    }
  }

  .widget_price_row {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 20px;
    }
  }
}
