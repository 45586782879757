.financing {
  &-subtitle {
    font-size: $font-size-12;
    color: $color-h2;
    font-weight: 600;
    padding-bottom: 0;
    text-transform: uppercase;
  }

  &-checkbox-subtitle {
    margin-bottom: 10px;
    color: $color-h2;
  }

  &-content-block {
    @extend .content-main;
    flex: none !important;
    margin-bottom: 10px;
  }

  &-row-main {
    @include row-spacebetween-center;
    column-gap: 50px;
    margin-bottom: 20px;

    .input-text .error {
      position: absolute;
    }

    .checkbox-container {
      margin-bottom: 0;
    }

    div {
      width: 100%;
      min-width: 0;
    }

    .suffix {
      width: fit-content;
    }
  }

  &-row-secondary {
    @extend .financing-row-main;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  &-row-features {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 15px;

    & > .checkbox-container {
      flex: 1;
      flex-grow: 0;
    }
  }

  &-rows-title {
    color: #525c69;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 20px;
  }

  &-main-input {
    width: 120px !important;
  }

  &-dropdown {
    padding: 30px;
    background: $white-bg;
    border-radius: 8px;
    width: 100%;

    .dropdown-summary {
      p {
        font-size: 12px;
        color: #525c69;
        font-weight: 600;
        padding-bottom: 0;
        text-transform: uppercase;
      }
    }
  }

  &-term-slider {
    width: 50%;
  }

  &-term-row {
    margin-bottom: 20px;
  }
}
