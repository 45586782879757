// GLOBALS

* {
  box-sizing: inherit;

  &:after,
  &:before {
    box-sizing: inherit;
  }
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  font-size: $font-size-17;
  font-weight: 300;
  height: 100%;
}

body {
  @include font-style-body;
  @include hyphenate;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $color-bg;
  color: $color-main;
  font-family: $font-main;
  height: 100%;
  overflow-x: hidden;
}

nav {
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

audio,
canvas,
iframe,
svg,
video {
  max-width: 100%;
  vertical-align: middle;
}

object {
  vertical-align: middle;
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

ol,
ul,
dl,
p,
address,
blockquote,
figure,
pre {
  margin: 0 0 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  margin-top: 0;

  ol,
  ul {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-rendering: optimizelegibility;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }
}

h1 {
  @include font-style-h1;
  color: $color-main;
}

h2 {
  @include font-style-h2;
  color: $color-main;
  padding: 15px 0;
}

a {
  color: $color-a;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-a;
    outline: 0;
  }
}

dl {
  margin-top: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 8px;
  margin-left: 0;
}

blockquote {
  @include font-size-20;
  border-left: 4px solid rgba($black, 0.1);
  margin-left: 0;
  padding: 12px 24px;

  &:first-child {
    margin-top: 0;
  }

  footer,
  small {
    @include font-size-14;
    color: $color-main;
    display: block;

    &:before {
      content: '— ';
    }
  }
}

abbr[title] {
  border-bottom: 1px dotted $color-main;
  cursor: help;
}

address {
  font-style: normal;
}

code,
kbd,
pre,
samp {
  font-family: $font-main;
}

code {
  @include font-size-14;
  background-color: rgba($black, 0.1);
  padding: 2px 4px;
}

kbd {
  @include font-size-14;
  background-color: $black;
  border-radius: 3px;
  color: $white;
  padding: 2px 4px;

  kbd {
    @include font-size-16;
    font-weight: 700;
    padding: 0;
  }
}

pre {
  @include font-size-14;
  background-color: rgba($black, 0.1);
  border: 1px solid rgba($black, 0.1);
  border-radius: 3px;
  display: block;
  padding: 12px;
  word-break: break-all;
  word-wrap: break-word;

  code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap;
  }
}

small {
  @include font-size-10;
}

hr {
  background-color: rgba($black, 0.1);
  border: 0;
  height: 2px;
  margin: 24px 0;
  width: 100%;
}

::placeholder {
  color: $color-main;
  opacity: 1;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  body:not(:-moz-handler-blocked) & {
    display: table-cell;
  }
}

legend {
  color: $color-main;
  display: table;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  @include font-size-16;
}

button {
  cursor: pointer;
}

input[type='search'] {
  box-sizing: border-box;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  vertical-align: baseline;

  + label {
    cursor: pointer;
    vertical-align: baseline;
  }
}

input[type='range'] {
  display: block;
  width: 100%;
}

input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea {
  appearance: none;
  border: none;
  box-shadow: none;
  line-height: inherit;
  max-width: 100%;
  padding: 7px 8px;
  transition: all 0.2s ease-out;
  vertical-align: middle;

  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

select {
  background-color: $white;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M10 13L15 8H5L10 13Z' fill='%23403f3d'/%3E %3C/svg%3E ");
  background-position: 100% center;
  background-position: calc(100% - 4px) center;
  background-repeat: no-repeat;
  padding: 7px 28px 7px 8px;

  &[multiple],
  &[size] {
    background-image: none;
    height: auto;
  }

  &:hover {
    outline: 0;
  }

  &:disabled {
    background-color: $color-disabled;
    cursor: default;
  }

  &:invalid {
    color: $color-error;
  }

  &::-ms-expand {
    display: none;
  }
}

textarea {
  height: 80px;
  resize: none;
  transition: none;
}
