.missing-wrapper {
  @include column-top-stretch;
  padding-left: 4%;
  padding-right: 4%;
  width: 80%;
  margin-top: 20px;
  align-self: center;

  @include media($width-xsm) {
    display: none;
  }
}

.missing-content {
  background-color: $white;
  border-radius: 8px;
  padding: 0 30px 20px;
  align-self: center;
  width: 100%;

  .missing-input {
    width: 47%;
  }
}

.missing-buttons {
  @include row-right-center;
  margin-top: 10px;
}
