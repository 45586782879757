.buttons-container {
  @include column-center-center;
  gap: 16px;
  padding-bottom: 30px;
  border-bottom: 1px solid $color-input-border;

  .first-button-wrapper {
    width: 100%;
    margin-bottom: 5px;
  }
}

.toggles-container {
  @include column-center-center;
  padding: 30px 0;
  gap: 15px;

  @include media($width-md) {
    margin-right: 2px;
    margin-left: 2px;
  }
}
