@import './variables';
@import './mixins';

.missing-data-page {
  .container {
    .row {
      margin-top: 25px;
      @include row-spacebetween-start;

      .autocomplete-input {
        width: 100%;
      }

      .street-number-input {
        width: 15%;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
