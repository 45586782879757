.counter-wrapper {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #a3a9b1;
    line-height: normal;
    padding-bottom: 3px;
  }
}

.moveRight {
  margin-left: auto;
}

.counter-content {
  display: flex;

  .counter-input {
    flex: 1;
    width: initial;

    input {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .button {
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s;
    height: 40px;
    width: 37px;
    background: #0275ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #0275ff;
    border-radius: 0;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.3);
      opacity: 0;
      border-radius: 100%;
      transform: translate(-50%, -50%) scale(1);
      transform-origin: center center;
      transition:
        opacity 0.5s,
        transform 0.5s;
    }

    &:active:after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(10);
      transition:
        transform 0.5s,
        opacity 0.5s;
    }

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0275ff;
    }

    .minus-sign {
      width: 8px;
      height: 1.5px;
      background: #fff;
    }

    .plus-sign {
      display: inline-block;
      width: 1px; /* Width of the vertical line */
      height: 8px; /* Height of the vertical line */
      position: relative;
      background-color: #fff;

      &:after {
        content: '';
        position: absolute;
        left: -3px; /* Adjusts the horizontal position of the horizontal line */
        top: 50%;
        width: 7px; /* Width of the horizontal line */
        height: 1px; /* Height of the horizontal line */
        background-color: #fff; /* Color of the plus sign */
        transform: translateY(-50%); /* Centers the horizontal line vertically */
      }
    }
  }
}
