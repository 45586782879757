.memory-counters-row {
  @include row-spacebetween-center;
  gap: 40px;
  margin-top: 35px;
  margin-bottom: 230px;

  .memory-counter {
    width: 47%;
  }

  &:last-child {
    margin-top: 15px;
  }
}
.memory-info-row {
  margin-bottom: 25px;
  @include font-size-12;
}
