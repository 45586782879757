// LAYOUT
$width: 1469px;
$width-small: 580px;
$width-content: 774px;
$width-right: 270px;
$width-left: 163px;

$width-md: 1280px;
$width-left-md: 73px;

$width-sm: 1024px;
$width-xsm: 430px;

$width-xsm: 530px;

// COLOR
$color-blue-500: #57d1e0;
$white: #ffffff;
$black: #000000;
$color-gradient-start: #31cbf8;
$color-gradient-end: #0075ac;
$color-bg: #f0f0f0;
$white-bg: #ffffff;
$color-main: #333;
$color-h2: #525c69;
$color-a: #000000;
$color-disabled: #cccccc;
$color-input-disable: #a3a9b142;
$color-button-disabled: #a3a9b137;
$color-error: #f91a1a;
$color-orange: #f90;
//colors button
$color-button: #fff;
$color-button-border: #979797;
$color-menu-crumb-disabled: #a3a9b1;
$color-button-text: #333333;
$color-button-hover: #d8d8d8;
$color-button-green: #bbed22;
$color-button-text-green: #333333;
$color-button-hover-green: #caff27;
$color-button-border-transparent: #979797;
$color-button-text-transparent: #333333;
$color-button-hover-transparent: #ffffff;
//forms
$color-checkbox: #1d1d1b;
$color-select-hover: #00a5cd;
$color-input-border: #a3a9b1;
$color-input-title: #a3a9b1;
$color-input-focus: #0275ff;
//theme
$color-bottom-bg: rgba(255, 255, 255, 0.8);
//popups
//elements
$color-box-shadow: rgba(0, 0, 0, 0.2);
//statuses

// TYPOGRAPHY
$font-main: 'Helvetica', 'Arial', sans-serif;

$font-size-10: 10px;
$line-height-10: auto;

$font-size-11: 11px;
$line-height-11: 14px;

$font-size-12: 12px;
$line-height-12: 14px;

$font-size-13: 13px;
$line-height-13: 16px;

$font-size-14: 14px;
$line-height-14: 1.5;

$font-size-15: 15px;
$line-height-15: 18px;

$font-size-16: 16px;
$line-height-16: 20px;

$font-size-17: 17px;
$line-height-17: 24px;

$font-size-18: 18px;
$line-height-18: 3;

$font-size-20: 20px;
$line-height-20: 1.4;

$font-size-21: 21px;
$line-height-21: 28px;

$font-size-22: 22px;
$line-height-22: 30px;

$font-size-24: 24px;
$line-height-22: 30px;

$font-size-28: 24px;
$line-height-28: 38px;

$font-size-30: 30px;
$line-height-30: auto;

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;

:export {
  colorGreen: $color-button-green;
}
