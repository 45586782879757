.toast {
  background-color: transparent;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  overflow: visible;
}

.toast-container {
  width: 100%;
  max-width: 420px;
  right: 3px;
  margin-top: -7px;
}

.error-message-container {
  @include column;
  box-shadow: -1px 11px 12px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -1px 11px 12px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 11px 12px 2px rgba(0, 0, 0, 0.2);

  .title-row {
    @include row-spacebetween-center;
    padding: 7px 15px;
    background-color: $color-error;

    .warning-icon {
      width: 23px;
      height: 23px;
    }

    .cross-icon {
      width: 19px;
      height: 19px;
    }
  }

  .content-row {
    padding: 16px;
    background-color: white;

    .content {
      @include font-size-14;
      font-weight: 400;
      color: black;
    }
  }
}
