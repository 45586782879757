.chartsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.chartContainer {
  width: 48%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .canvas {
    width: 250px;
    height: 250px;
    max-width: 250px;
    max-height: 250px;
  }

  .chartTitle {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .chartValue {
    position: absolute;
    top: 142px;
    align-self: center;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 500;
  }
}

.sufficience-inputs-row {
  @include row-spacebetween-center;

  .sufficience-input {
    width: 32%;
  }
}

.resetRow {
  @include row;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;

  .resetTitle {
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 700;
    line-height: 13.8px;
    text-align: left;
    text-decoration: underline;
  }
}
