//elements
.loading {
  @include column-center-center;
  position: fixed;
  background: $color-disabled;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.bread-crumbs-menu {
  @include column;
  width: 100%;
  height: 90px;
  border-radius: 5px;
  background-color: $white-bg;
  margin-bottom: 30px;
  padding: 20px 60px 40px;

  @include media($width-md) {
    padding: 20px 40px 40px;
    margin-bottom: 20px;
  }

  .inner-container {
    @include row-spacebetween-center;
    position: relative;

    &.single {
      justify-content: center;
    }
  }

  .breakpoint {
    position: relative;
    @include column-center-center;
    text-overflow: ellipsis;

    .circle {
      z-index: 3;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: $color-menu-crumb-disabled;
      position: relative;

      &.ready {
        background-color: $color-button-green;
      }
    }

    .breakpoint-title {
      @include font-size-14;
      padding: 0 1px;
      font-weight: 400;
      position: absolute;
      top: 150%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: initial;

      &.overlap-text {
        max-width: 90px;

        @include media($width-md) {
          max-width: 60px;
        }
      }
    }
  }

  .background-line {
    top: 10px;
    transform: translateY(-50%);
    align-self: center;
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $color-button-hover;
  }

  .progress-line {
    top: 10px;
    transform: translateY(-50%);
    z-index: 2;
    position: absolute;
    height: 5px;
    background-color: $color-button-green;
    transition: 0.5s;
    width: 0;
  }
}

.toggle-switch {
  @include row-left-center;
  width: 100%;
  gap: 10px;

  &.disabled {
    opacity: 0.5;
  }

  &.collapsed {
    flex-wrap: wrap;
  }

  .toggle-label {
    @include font-size-14;
    font-weight: 700;
    max-width: 80%;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;

    &.collapsed {
      max-width: initial;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 32px;
    min-width: 32px;
    height: 22px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-input-title;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: $color-button-green;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $color-button-green;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }

    .slider.round {
      border-radius: 20px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.progress-indicator {
  @include column;
  width: 100%;

  .progress-header {
    @include font-size-14;
    @include row-spacebetween-center;
    margin-bottom: 10px;
    width: 100%;
    font-weight: 700;

    .progress-percentage {
      font-weight: bold;
    }
  }

  .progress-bar {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;

    .progress-bar-fill {
      height: 9px;
      background-color: $color-button-green;
      border-radius: 9px;
      transition: width 0.3s ease-in-out;
    }
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;

  &.full-width {
    width: 100%;
  }

  .tooltip-box {
    position: absolute;
    visibility: hidden;
    width: max-content;
    padding: 0.5em 1em;
    color: $black;
    text-align: center;
    border-radius: 6px;
    background-color: $white;
    z-index: 1000;
    box-shadow: 0px 0px 10px $color-box-shadow;
    transition:
      visibility 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    opacity: 0;
    max-width: 300px;

    // Tooltip arrow
    &::after {
      content: '';
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }

  &.visible .tooltip-box {
    visibility: visible;
    opacity: 1;
  }

  // Top position by default
  &.position-top .tooltip-box {
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);

    &::after {
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-color: $white transparent transparent transparent;
    }
  }

  &.bottom-buttons .tooltip-box {
    left: -10%;

    &::after {
      left: 70%;
    }
  }

  // Bottom position
  &.position-bottom .tooltip-box {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);

    &::after {
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-color: transparent transparent $white transparent;
    }
  }

  // Left position
  &.position-left .tooltip-box {
    right: calc(100% + 5px);
    top: 50%;
    transform: translateY(-50%);

    &::after {
      right: -10px;
      top: 50%;
      margin-top: -5px;
      border-color: transparent transparent transparent $white;
    }
  }

  // Right position
  &.position-right .tooltip-box {
    left: calc(100% + 5px);
    top: 50%;
    transform: translateY(-50%);

    &::after {
      left: -10px;
      top: 50%;
      margin-top: -5px;
      border-color: transparent $white transparent transparent;
    }
  }
}

.image-button {
  @include row-left-center;
  padding: 10px;
  border: 1px solid $color-input-border;
  border-radius: 5px;
  background-color: $white;
  cursor: pointer;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
  flex: 1;
  box-sizing: border-box;
  height: 76px;
  overflow: hidden;

  &.active {
    border: 4px solid $color-button-green;
    margin: -3px;
    box-shadow: 0 0 5px $color-button-green;
  }

  &.small {
    height: 56px;
  }

  .image-wrapper {
    height: 48px;
    width: 48px;
    margin-right: 10px;

    img {
      object-fit: contain;
      height: 100%;
      width: auto;
    }

    &.small {
      height: 35px;
      width: auto;
    }

    &.full-image {
      width: 100%;
    }
  }

  span {
    font-size: 16px;

    &.small {
      font-size: 14px;
    }
  }

  &.center {
    justify-content: center;
  }
}
