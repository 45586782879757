// variables
//@import './fonts';
@import './variables';
@import './mixins';

// globals
@import './keyframes';
@import './normalize';
@import './reset';
@import './global';
@import './modifier';
@import './form';
@import './popups';
@import './elements';
@import './buttons';
@import './themes';
@import './leftArea';
@import './basicData';
@import './photovoltaik';
@import './wallbox';
@import './blackoutBox';
@import './hotWaterPump';
@import './memoryExtension';
@import './missingRequiredData';
@import './financing';

@import 'components/index';

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100vh;

  .content {
    @include row-spacebetween-start;
    padding-left: 1.5%;
    padding-right: 1%;
    align-self: center;
    width: 100%;
    padding: 20px;
    height: 100%;

    @include media($width-xsm) {
      display: none;
    }

    .without-menu {
      @include row;
      flex: 1;
      height: 100%;
      overflow: hidden;

      .content-inner {
        @include column-top-stretch;
        flex: 1;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;

        @include media($width-md) {
          flex: 1;
        }

        .content-main {
          background-color: $white-bg;
          border-radius: 8px;
          padding: 20px 30px 20px;
          flex: 1;
          width: 100%;
        }
      }

      .page-content {
        @include column-left-top;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }

      @include media($width-sm) {
        height: 93%;
        flex-direction: column-reverse;
        justify-items: flex-start;

        .content-inner {
          width: 100%;
        }
      }
    }

    @include media($width-md) {
      justify-items: flex-start;
    }
  }

  .responsive-error {
    margin-left: 2%;
    margin-right: 2%;
    display: none;
    background-color: $white;
    padding: 40px 30px;

    @include media($width-xsm) {
      display: flex;
      @include column-left-top;
    }

    .responsive-error-title {
      font-size: 30px;
      margin-bottom: 40px;
    }

    .responsive-error-subtitle {
      font-size: 18px;
    }
  }

  .loading-icon {
    @include column-center-center;
    margin-top: 64px;
  }
}

.wrapper {
  @include wrapper-xy();
}

.notfound-page {
  background-color: $white;
  .container {
    margin: 40px 0px;
    .row {
      .column-left-top {
        @include column-left-top;
        width: 50%;

        .desc {
          @include font-size-24;
          padding-top: 40px;
        }
      }

      .column-right-top {
        @include column-right-top;
        width: 50%;
      }
    }
  }
}

.hidden {
  @include hidden;
}
