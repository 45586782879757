//aligns
@mixin column {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
@mixin row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@mixin column-center-center {
  @include column;
  justify-content: center;
  align-items: center;
}
@mixin column-top-stretch {
  @include column;
  justify-content: flex-start;
  align-items: stretch;
}

@mixin column-top-center {
  @include column;
  justify-content: flex-start;
  align-items: center;
}

@mixin column-right-center {
  @include column;
  justify-content: center;
  align-items: flex-end;
}
@mixin column-right-top {
  @include column;
  justify-content: flex-start;
  align-items: flex-end;
}
@mixin column-left-top {
  @include column;
  justify-content: flex-start;
  align-items: flex-start;
}
@mixin column-left-spacebetween {
  @include column;
  justify-content: space-between;
  align-items: flex-start;
}
@mixin row-center-center {
  @include row;
  justify-content: center;
  align-items: center;
}
@mixin row-left-center {
  @include row;
  justify-content: flex-start;
  align-items: center;
}
@mixin row-left-end {
  @include row;
  justify-content: flex-start;
  align-items: flex-end;
}
@mixin row-right-center {
  @include row;
  justify-content: flex-end;
  align-items: center;
}
@mixin row-spacearound-center {
  @include row;
  justify-content: space-around;
  align-items: center;
}
@mixin row-spacebetween-center {
  @include row;
  justify-content: space-between;
  align-items: center;
}
@mixin row-spacebetween-start {
  @include row;
  justify-content: space-between;
  align-items: flex-start;
}
@mixin row-spacebetween-end {
  @include row;
  justify-content: space-between;
  align-items: flex-end;
}
@mixin row-left-top {
  @include row;
  justify-content: flex-start;
  align-items: flex-start;
}

// HELPERS
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// media
@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin media-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin media-y($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}

// TYPOGRAPHY
@mixin font-size-10 {
  font-size: $font-size-10;
  line-height: $line-height-10;
}

@mixin font-size-12 {
  font-size: $font-size-12;
  line-height: $line-height-12;
}

@mixin font-size-14 {
  font-size: $font-size-14;
  line-height: $line-height-14;

  @include media($width-md) {
    font-size: $font-size-12;
    line-height: $line-height-12;
  }
}

@mixin font-size-16 {
  font-size: $font-size-16;
  line-height: $line-height-16;

  @include media($width-small) {
    font-size: $font-size-14;
  }
}

@mixin font-size-20 {
  font-size: $font-size-20;
  line-height: $line-height-20;

  @include media($width-small) {
    font-size: $font-size-18;
  }
}

@mixin font-size-24 {
  font-size: $font-size-24;
}

@mixin font-size-30 {
  font-size: $font-size-30;
  line-height: $line-height-30;
}

@mixin font-style-h1 {
  font-size: $font-size-30;
}

@mixin font-style-h2 {
  font-size: $font-size-14;
  font-weight: bold;
  color: $color-h2;
}

@mixin font-style-body {
  font-size: $font-size-14;
  line-height: $line-height-14;
}

// LAYOUT
@mixin flatlist {
  ol,
  ul {
    list-style: none;
    padding-left: 24px;
    text-indent: -24px;

    li {
      &:before {
        content: '\2022';
        display: inline-block;
        min-width: 24px;
        text-indent: 0;
        padding-right: 4px;
      }
    }
  }

  ol {
    li {
      counter-increment: list-ordered;

      &:before {
        content: counter(list-ordered) '. ';
        text-indent: 0;
      }
    }
  }
}

@mixin wrapper-xy($width: $width, $padding: 0, $align: center) {
  @if $align == center {
    margin-left: auto;
    margin-right: auto;
  }

  @if $align == right {
    margin-left: auto;
  }

  width: $width;

  @include media($width) {
    width: 100%;
    padding: 0 $padding + px;
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  display: none;
}

@mixin visible {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
  overflow: visible !important;
  visibility: visible !important;
}

@mixin hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  //hyphens: auto;
}

@mixin flex-flow($width, $margin) {
  display: flex;
  flex-wrap: wrap;
  margin: calc(#{$margin} * -1);

  > * {
    flex-grow: 1;
    margin: $margin;
    max-width: 100%;
    min-width: calc(#{$width} - (#{$margin} * 2));
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin touchArea {
  width: 44px;
  height: 44px;
  cursor: pointer;
}
